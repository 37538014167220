#contact h1 {
  font-family: "Chivo", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30px;
}

#contact a {
  text-decoration: none;
  color: #365d7b;
}

.submit-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
