#services h1 {
  font-family: "Chivo", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 30px;
}

.pi-list {
  line-height: 30px;
}

.carousel-item {
  position: relative;
}

.carousel-item img {
  height: 500px;
  width: 100%;
  /*overflow: hidden;*/
  object-fit: cover;
  /*object-fit: scale-down;*/
  object-position: center;
}
