@import url("https://fonts.googleapis.com/css?family=Chivo:300,700|Playfair+Display:700i");

body {
  font-family: "Chivo", sans-serif;
  font-weight: 300;
}

body,
html {
  height: 100vh;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-style: italic;
}

.page-wrapper {
  min-height: 100vh;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -200px;
}

.push {
  height: 200px;
}
