.footer {
  height: 140px;
  margin-top: 60px;
  background: #222c38;
}

.footer-inner {
  padding: 20px 0;
  color: #f7f7f7;
}

.footer p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
}

.uscg {
  width: 75px;
}
