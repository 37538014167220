.bg-wrapper {
  border-top: 1px solid #365d7b;
  border-bottom: 1px solid #365d7b;
  position: relative;
  width: 100vw;
  height: 300px;
  margin-bottom: 80px;
}

.bg {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.info {
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.info h1 {
  font-size: 40px;
}
.info h2 {
  font-size: 30px;
}

h5 {
  font-family: "Chivo", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.headshot-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .headshot {
    width: 35vw !important;
    height: 35vw !important;
    margin-bottom: 50px;
  }
}

.headshot {
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  overflow: hidden;
  max-width: 300px;
  max-height: 300px;
}

.services-offered-wrapper {
  text-align: center;
  background: #eeeeee;
  width: 100vw;
  margin-top: 100px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  padding-top: 50px;
}

.services-offered-wrapper h3 {
  margin-bottom: 30px;
}

.services-offered-img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 20px;
}

.services-offered-entry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-link {
  text-decoration: none;
  color: #365d7b;
  height: 50px;
  margin-top: 20px;
}

.services-offered .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
